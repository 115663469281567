import StepSelectInstrument from '@/components/steps/StepSelectInstrument.vue';
import StepSelectPlanCategory from '@/components/steps/StepSelectPlanCategory.vue';
import StepSelectPayment from '@/components/steps/StepSelectPayment.vue';
import StepReset from '@/components/steps/StepReset.vue';
import StepRedeemVoucher from '@/components/steps/StepRedeemVoucher.vue';
import StepSelectPlan from '@/components/steps/StepSelectPlan.vue';
import StepOrderOverview from '@/components/steps/StepOrderOverview.vue';
import { TranslationString } from '@/models/Language';
import { Component } from 'vue';
import i18n from '@/plugins/i18n';

export type SignupStep = 'instrument-selection' | 'plan-selection' | 'order-overview' | 'redeem-voucher' | 'reset' | 'plan-category-selection' | 'payment-selection';

export type SignupStepMeta<T extends SignupStep = SignupStep> = {
	id: T;
	name: TranslationString;
	title: TranslationString;
	component: Component;
};

export const signupStepMetaMap: { [K in SignupStep]: SignupStepMeta<K> } = {
	'instrument-selection': {
		id: 'instrument-selection',
		name: () => i18n.global.t('steps.name.instrumentSelection'),
		title: '',
		component: StepSelectInstrument,
	},
	'plan-category-selection': {
		id: 'plan-category-selection',
		name: () => i18n.global.t('steps.name.instrumentSelection'),
		title: '',
		component: StepSelectPlanCategory,
	},
	'plan-selection': {
		id: 'plan-selection',
		name: () => i18n.global.t('steps.name.planSelection'),
		title: '',
		component: StepSelectPlan,
	},
	'payment-selection': {
		id: 'payment-selection',
		name: () => i18n.global.t('steps.name.paymentSelection'),
		title: '',
		component: StepSelectPayment,
	},
	'order-overview': {
		id: 'order-overview',
		name: () => i18n.global.t('steps.name.orderOverview'),
		title: '',
		component: StepOrderOverview,
	},
	'redeem-voucher': {
		id: 'redeem-voucher',
		name: () => i18n.global.t('steps.name.redeemVoucher'),
		title: () => i18n.global.t('steps.title.redeemVoucher'),
		component: StepRedeemVoucher,
	},
	'reset': {
		id: 'reset',
		name: () => i18n.global.t('steps.name.reset'),
		title: '',
		component: StepReset,
	},
};

export const getSignupStepMeta = <T extends SignupStep = SignupStep>(signupStep: T): SignupStepMeta<T> => signupStepMetaMap[signupStep];

export const getStepName = (signupStep: SignupStep): TranslationString => {
	const meta = getSignupStepMeta(signupStep);
	return meta.name;
};
