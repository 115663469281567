import { Instrument } from '@/utilities/Instrument';
import { BillingOccurence } from './BillingOccurence';
import { Localization } from './Localization';

export type PlanType = 'single' | 'plus';

export type PlanTypeDescription = {
	name: Localization;
	description?: Localization;
	benefits: Localization[];
};

export type PlanTypeCard = PlanTypeDescription & {
	billingOccurence: BillingOccurence;
	pricePerBillingOccurenceInCents: number;
	isTrial: boolean;
	instruments: Instrument[];
	type: PlanType;
	comparisonDiscountInPercent: number | null;
	discountInPercent: number | null;
};

export const planTypeDescriptions: { [K in PlanType]: PlanTypeDescription } = {
	single: {
		name: (t) => t('planType.single.name'),
		description: (t) => t('planType.single.description'),
		benefits: [
			(t) => t('planType.single.benefit.1'),
			(t) => t('planType.single.benefit.2'),
			(t) => t('planType.single.benefit.3'),
		],
	},
	plus: {
		name: (t) => t('planType.plus.name'),
		description: (t) => t('planType.plus.description'),
		benefits: [
			(t) => t('planType.plus.benefit.1'),
			(t) => t('planType.plus.benefit.2'),
			(t) => t('planType.plus.benefit.3'),
		],
	},
};
