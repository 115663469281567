<script setup lang="ts">
import StepsBreadcrumbs from '@/components/StepsBreadcrumbs.vue';
import { useMainStore } from '@/store/main';
import { computed } from 'vue';

const mainStore = useMainStore();
const hasSignupType = computed(() => mainStore.signupType);
</script>

<template>
	<div
		v-if="hasSignupType"
		class="fixed flex items-center h-[78px] border-t border-solid border-gray-300 bg-white bottom-0 w-full z-100 widescreen:w-[calc(100%-600px)] widescreen:left-[600px]"
	>
		<div class="container px-6">
			<StepsBreadcrumbs />
		</div>
	</div>
</template>
