<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps<{
	tooltip: string | object;
	absolutePositioning?: boolean;
}>();

const computedTooltip = computed(() => {
	if (typeof props.tooltip === 'string') {
		return {
			content: props.tooltip,
		};
	}

	return {
		...props.tooltip,
	};
});
</script>

<template>
	<span class="relative">
		<slot />
		<div
			v-tippy="computedTooltip"
			:class="{ 'transform translate-x-2 -translate-y-1/2 absolute m-0 -right-4 top-1/2': !!absolutePositioning }"
			class="bg-gray-500 mx-2 inline-flex items-center justify-center w-4 h-4 rounded-full"
		>
			<svg
				viewBox="0 0 1.67 8.17"
				class="h-[10px] w-auto"
			>
				<path
					d="M1.5,8.17H.17V2.83H1.5ZM.83,0a.84.84,0,0,1,.84.83.85.85,0,0,1-.84.84A.84.84,0,0,1,0,.83.83.83,0,0,1,.83,0Z"
					fill="#ffffff"
				/>
			</svg>
		</div>
	</span>
</template>
