import { createPlausible } from '.';

export const plausible = createPlausible(
	import.meta.env.PROD
		? {
				apiHost: 'https://plausible.sunlab.de',
				domain: 'music2me.com,signup.music2me.com',
			}
		: {},
);
