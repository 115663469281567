<script setup lang="ts">
import { useLanguage } from '@/composables/useLanguage';
import type { LanguageMenuItem } from '@sunlabde/m2m-library/dist/src/models/Language';
import { LanguageMenu } from '@sunlabde/m2m-library';
import { computed } from 'vue';

const {
	composeLinkWithNewLocale,
	availableLanguages,
	selectedLanguage,
} = useLanguage();

const mappedSelectedLanguage = computed(() => ({
	label: selectedLanguage.value.name,
	link: composeLinkWithNewLocale(selectedLanguage.value.code),
	languageCode: selectedLanguage.value.code,
}));

const languageMenuItems = computed((): LanguageMenuItem[] => Object.values(availableLanguages.value).map((locale) => ({
	label: locale.nativeName,
	link: composeLinkWithNewLocale(locale.code),
	languageCode: locale.code,
})));
</script>

<template>
	<LanguageMenu
		:available-languages="languageMenuItems"
		:model-value="mappedSelectedLanguage"
	/>
</template>
