<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import PaymentBox from '@/components/PaymentBox.vue';
import { storeToRefs } from 'pinia';
import { useMainStore } from '@/store/main';
import { useProductsStore } from '@/store/products';
import { AppButton } from '@sunlabde/m2m-library';

const emits = defineEmits<{
	(e: 'signupTrackingEvent', name: string): void;
	(e: 'continue'): void;
	(e: 'goBack'): void;
}>();

const isPaymentDataValid = ref(false);
const waitingForPayment = ref(false);
const { t } = useI18n();

const mainStore = useMainStore();
const { selectedPlanCategory } = storeToRefs(mainStore);

const productsStore = useProductsStore();
const { allowsTrialOrder } = storeToRefs(productsStore);

const { endOfTrialDate } = storeToRefs(useMainStore());

const stepTitle = computed(() => {
	if (allowsTrialOrder.value) {
		return t('subscribe.step.plan.title_trial');
	}

	return t('subscribe.step.plan.title');
});
const validateStepSelection = computed(() => isPaymentDataValid.value);
const stepSubtitle = computed(() => {
	if (selectedPlanCategory.value === 'combo') {
		if (allowsTrialOrder.value) {
			return t('subscribe.step.plan.subtitle_combo_trial', [endOfTrialDate.value.toFormat('dd.MM.yyyy')]);
		}
		return t('subscribe.step.plan.subtitle_combo');
	}

	if (allowsTrialOrder.value) {
		return t('subscribe.step.plan.subtitle_trial', [endOfTrialDate.value.toFormat('dd.MM.yyyy')]);
	}

	return t('subscribe.step.plan.subtitle');
});

const nextStep = () => {
	emits('continue');
};

const unsetPlan = () => {
	emits('goBack');
};

const paymentChangeHandler = (isValid: boolean) => {
	isPaymentDataValid.value = isValid;
};

const paymentFormRef = ref<typeof PaymentBox>();

const submitPaymentForm = () => {
	waitingForPayment.value = true;
	paymentFormRef.value?.submit();
};

const onPaymentFormSuccess = () => {
	waitingForPayment.value = false;
	nextStep();
};

const onSelectPaymentMethod = () => {
	emits('signupTrackingEvent', 'selectPaymentMethod');
};
</script>

<template>
	<div>
		<h2 class="text-center text-[24px] mb-4 mx-auto tablet:w-8/12 desktop:w-10/12 widescreen:w-8/12 font-bold">
			{{ stepTitle }}
		</h2>
		<p class="text-center mb-8 text-gray-700 leading-normal mx-auto phablet:w-8/12 desktop:w-10/12 widescreen:w-8/12">
			{{ stepSubtitle }}
		</p>
		<div class="block relative">
			<div
				key="paymentSelection"
				class="w-full mb-8"
			>
				<PaymentBox
					ref="paymentFormRef"
					@change="paymentChangeHandler"
					@submit="submitPaymentForm"
					@success="onPaymentFormSuccess"
					@select-payment-method="onSelectPaymentMethod"
					@change-plan="unsetPlan"
				/>
				<div
					key="stepFooter"
					class="mt-8 text-center"
				>
					<AppButton
						:is-disabled="!validateStepSelection"
						:is-loading="waitingForPayment"
						data-cy="button_nextStep"
						class="mx-auto"
						@click="submitPaymentForm"
					>
						{{ $t('button.nextStep') }}
					</AppButton>
					<p class="text-xs leading-normal mt-4 max-w-[560px] mx-auto">
						{{ $t('notification.info.reviewOrderNextStep') }}
					</p>
				</div>
			</div>
		</div>
	</div>
</template>
