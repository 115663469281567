import { defineStore } from 'pinia';
import * as Sentry from '@sentry/vue';
import {
	ResultAsync, ok, err, Result, okAsync, errAsync,
} from 'neverthrow';
import Cookies from 'js-cookie';
import { watch, WatchCallback, WatchStopHandle } from 'vue';
import AppError from '@/models/AppError';
import {
	COOKIE_API_ACCESS_TOKEN, COOKIE_API_REFRESH_TOKEN, COOKIE_USER_INFO, removeCookie, setCookie,
} from '@/models/CustomCookies';
import { AuthErrorCodes } from '@/enums/ErrorCodes';
import { useOAuthApi } from '@/api/oauth';
import { TokenResponse } from '@/models/TokenResponse';
import { useApiAuthHooks } from '@/composables/useApiAuthHooks';
import contentApiClient from '@/api/contentApiClient';
import { AuthUser } from '@/models/AuthUser';
import { NewUserData } from '@/models/NewUserData';
import { UserResponse } from '@/models/UserResponse';
import logger from '@/plugins/Logger';
import { createUser } from '@/api/userSignup';
import { SupabaseLoginOptions } from '@/models/Auth';

const oAuthAPI = useOAuthApi();

export interface AuthState {
	user: AuthUser | null;
	authenticationTask: ResultAsync<string, AppError> | null;
	authenticationError: AppError | null;
	onAuthenticationError: ((error: AppError) => void) | null;
}

export const useAuthStore = defineStore({
	id: 'auth',

	state: (): AuthState => ({
		user: null,
		authenticationTask: null,
		authenticationError: null,
		onAuthenticationError: null,
	}),

	actions: {
		getAccessToken(): Result<string, AppError> {
			const accessToken = Cookies.get(COOKIE_API_ACCESS_TOKEN);

			if (!accessToken) {
				return err(new AppError(AuthErrorCodes.ACCESS_TOKEN_MISSING));
			}

			return ok(accessToken);
		},

		getRefreshToken(): Result<string, AppError> {
			const refreshToken = Cookies.get(COOKIE_API_REFRESH_TOKEN);

			if (!refreshToken) {
				return err(new AppError(AuthErrorCodes.REFRESH_TOKEN_MISSING));
			}

			return ok(refreshToken);
		},

		saveTokenResponse(tokens: TokenResponse): TokenResponse {
			Sentry.addBreadcrumb({
				category: 'Auth',
				message: 'Save new tokens as cookies',
				level: 'info',
			});

			setCookie(COOKIE_API_ACCESS_TOKEN, tokens.access_token, { expires: tokens.expires_at });
			setCookie(COOKIE_API_REFRESH_TOKEN, tokens.refresh_token);

			return tokens;
		},

		saveUserInfo(userResponse: UserResponse): UserResponse {
			Sentry.addBreadcrumb({
				category: 'Auth',
				message: 'Save user info as cookie',
				level: 'info',
			});

			setCookie(COOKIE_USER_INFO, JSON.stringify(userResponse));
			return userResponse;
		},

		saveAuthUser(user: AuthUser): AuthUser {
			this.user = user;
			return user;
		},

		removeRefreshToken() {
			Sentry.addBreadcrumb({
				category: 'Auth',
				message: 'Remove refresh token',
				level: 'info',
			});

			removeCookie(COOKIE_API_REFRESH_TOKEN);
		},

		removeAccessToken() {
			Sentry.addBreadcrumb({
				category: 'Auth',
				message: 'Remove access token',
				level: 'info',
			});

			removeCookie(COOKIE_API_ACCESS_TOKEN);
		},

		removeAuthentication() {
			Sentry.addBreadcrumb({
				category: 'Auth',
				message: 'Remove complete authentication',
				level: 'info',
			});

			this.user = null;
			this.removeAccessToken();
			this.removeRefreshToken();
			removeCookie(COOKIE_USER_INFO);
		},

		asAuthenticationTask(task: () => ResultAsync<string, AppError>): ResultAsync<string, AppError> {
			if (!this.authenticationTask) {
				logger.info('Create new authentication task');
				Sentry.addBreadcrumb({
					category: 'Auth',
					message: 'Create new authentication task',
					level: 'info',
				});

				const newTask = task()
					.map((result) => {
						logger.info('Remove authentication task after success');
						this.authenticationTask = null;
						return result;
					})
					.mapErr((error) => {
						logger.info('Remove authentication task after error');
						this.authenticationTask = null;
						return error;
					});

				this.authenticationTask = newTask;
			} else {
				logger.info('Use existing authentication task');
				Sentry.addBreadcrumb({
					category: 'Auth',
					message: 'Use existing authentication task',
					level: 'info',
				});
			}

			return this.authenticationTask as ResultAsync<string, AppError>;
		},

		refreshAccessToken(): ResultAsync<string, AppError> {
			logger.info('Call refreshAccessToken function');
			Sentry.addBreadcrumb({
				category: 'Auth',
				message: 'Call refreshAccessToken function',
				level: 'info',
			});

			return this.asAuthenticationTask(() => {
				this.removeAccessToken();

				return this.getRefreshToken()
					.asyncAndThen(oAuthAPI.refreshAccessToken)
					.map(this.saveTokenResponse)
					.map((tokenResponse) => tokenResponse.access_token);
			});
		},

		getAccessTokenOrRefresh(): ResultAsync<string, AppError> {
			return this.getAccessToken()
				.asyncAndThen((accessToken) => okAsync(accessToken))
				.orElse(this.refreshAccessToken);
		},

		getCurrentUser(): ResultAsync<AuthUser, AppError> {
			return this.getAccessToken()
				.asyncAndThen((token) => oAuthAPI.getCurrentUserInfo(token))
				.map(this.saveUserInfo)
				.andThen(AuthUser.fromUserResponseSafely)
				.map(this.saveAuthUser);
		},

		getCurrentUserOrLogout(): ResultAsync<AuthUser, AppError> {
			return this.getCurrentUser()
				.mapErr((error) => {
					Sentry.withScope((scope) => {
						scope.setLevel('info');
						scope.setTransactionName('getCurrentUser failed');
						Sentry.captureException(error);
					});
					this.removeAuthentication();
					return error;
				});
		},

		initAuthentication(): ResultAsync<AuthUser, AppError> {
			if (this.getAccessToken().isOk()) {
				logger.info('Initialize with access token');
				Sentry.addBreadcrumb({
					category: 'Auth',
					message: 'Initialize with access token',
					level: 'info',
				});

				return this.getCurrentUserOrLogout();
			}

			if (this.getRefreshToken().isOk()) {
				logger.info('Initialize with refresh token');
				Sentry.addBreadcrumb({
					category: 'Auth',
					message: 'Initialize with refresh token',
					level: 'info',
				});

				return this.refreshAccessToken()
					.andThen(this.getCurrentUserOrLogout);
			}

			logger.info('Abort initializing because of missing tokens');
			Sentry.addBreadcrumb({
				category: 'Auth',
				message: 'Abort initializing because of missing tokens',
				level: 'info',
			});

			return errAsync(new AppError(AuthErrorCodes.ACCESS_TOKEN_MISSING));
		},

		getAuthUser(): ResultAsync<AuthUser, AppError> {
			if (!this.user) {
				return this.initAuthentication();
			}

			return okAsync(this.user);
		},

		loginUser(username: string, password: string): ResultAsync<AuthUser, Error> {
			Sentry.addBreadcrumb({
				category: 'Auth',
				message: 'Attempt to login user',
				level: 'info',
			});

			const loginResult = oAuthAPI.loginUserWithPassword(username, password);

			const onError = (error: Error) => new AppError(AuthErrorCodes.LOGIN_FAILED, error);

			return loginResult
				.map(this.saveTokenResponse)
				.andThen(this.initAuthentication)
				.mapErr(onError);
		},

		loginUserWithSupabase(options: SupabaseLoginOptions): ResultAsync<{ user: AuthUser; accountCreated: boolean }, Error> {
			Sentry.addBreadcrumb({
				category: 'Auth',
				message: 'Attempt to login user with supabase',
				level: 'info',
			});

			const loginResult = oAuthAPI.loginWithSupabaseToken(options);

			const onError = (error: Error) => new AppError(AuthErrorCodes.LOGIN_FAILED, error);

			return loginResult
				.map(this.saveTokenResponse)
				.andThen((tokenResponse) => this.initAuthentication().map((user) => ({
					user,
					accountCreated: tokenResponse.account_created,
				})))
				.mapErr(onError);
		},

		logoutUser(): Result<boolean, Error> {
			Sentry.addBreadcrumb({
				category: 'Auth',
				message: 'Manually logout user',
				level: 'info',
			});

			const accessToken = this.getAccessToken();

			if (accessToken.isOk()) {
				oAuthAPI.revokeAccessToken(accessToken.value);
			}

			this.removeAuthentication();

			return ok(true);
		},

		createUser(user: NewUserData): ResultAsync<Response, AppError> {
			return createUser(user);
		},

		setupAuthenticationHooks() {
			const apiAuthHooks = useApiAuthHooks();

			apiAuthHooks.setupAuthenticationHooks({
				client: contentApiClient,
				getAccessToken: () => this.getAccessTokenOrRefresh(),
				refreshAccessToken: () => this.refreshAccessToken(),
				onUnauthorized: (error) => {
					this.removeAuthentication();

					if (this.onAuthenticationError) {
						this.onAuthenticationError(error);
					}
				},
			});
		},
	},

	getters: {
		isAuthenticated: (state): boolean => !!state.user,
		isVerified: (state): boolean => !!state.user && state.user.emailVerified,
	},
});

export const useAuthStoreWatcher = () => {
	const authStore = useAuthStore();

	const onUserLogin = (callback: WatchCallback<AuthUser>): WatchStopHandle => watch(() => authStore.user, (value, oldValue, onInvalidate) => {
		if (value === null || !!oldValue) {
			return;
		}

		callback(value, oldValue, onInvalidate);
	}, { immediate: true });

	const onUserLogout = (callback: WatchCallback): WatchStopHandle => watch(() => authStore.user, (value, oldValue, onInvalidate) => {
		if (value !== null || !oldValue) {
			return;
		}

		callback(value, oldValue, onInvalidate);
	}, { immediate: true });

	const onUserUpdate = (callback: WatchCallback): WatchStopHandle => watch(() => authStore.user, (value, oldValue, onInvalidate) => {
		if (value === null || !oldValue) {
			return;
		}

		callback(value, oldValue, onInvalidate);
	}, { immediate: true });

	return {
		onUserLogin,
		onUserLogout,
		onUserUpdate,
	};
};
