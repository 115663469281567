<script lang="ts" setup>
import { useCartStore } from '@/store/cart';
import { useMainStore } from '@/store/main';
import { useProductsStore } from '@/store/products';
import { onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();
const cartStore = useCartStore();
const mainStore = useMainStore();
const productsStore = useProductsStore();

onMounted(() => {
	const query = { ...route.query };
	mainStore.planCategory = null;
	mainStore.setSignupType(productsStore.portal?.options.defaultSignupType || null);
	cartStore.clearCart();
	delete query.type;
	router.push({ name: 'subscribe', query: { ...query, step: 1 } });
});
</script>

<template>
	<div />
</template>
