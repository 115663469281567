import { ResultAsync } from 'neverthrow';
import { PortalCategoryCarts } from '@/models/PortalCart';
import { responseWithErrorHandler } from '.';
import contentApiClient from './contentApiClient';

/**
 * New 3.2 portal endpoint which uses users countryCode to determine which products to return
 * @param portalId
 * @param countryCode
 * @returns PortalCategoryCarts
 */
export const getPortalCartsForCountry = (portalId: string, countryCode: string): ResultAsync<PortalCategoryCarts, Error> => {
	const data: Promise<PortalCategoryCarts> = contentApiClient.client.get(`portal/${portalId}`, {
		headers: {
			'api-version': '3.2',
		},
		searchParams: {
			locale: countryCode,
		},
	}).json();

	return responseWithErrorHandler(data);
};

export const getPortalCarts = (options: { portalId: string; countryCode: string }) => getPortalCartsForCountry(options.portalId, options.countryCode);
