import { isTrueValue } from '@/utilities/Helpers';
import logger from '@/plugins/Logger';
import { PartialRecord } from './PartialRecord';

export enum FeatureFlag {
	SPANISH = 'SPANISH',
	DRUMS = 'DRUMS',
	COUNTRY_CODE_PROFILE = 'COUNTRY_CODE_PROFILE',
	COUNTRY_PORTALS = 'COUNTRY_PORTALS',
	SOCIAL_LOGIN = 'SOCIAL_LOGIN',
	SOCIAL_PROVIDER_GOOGLE = 'SOCIAL_PROVIDER_GOOGLE',
	SOCIAL_PROVIDER_DISCORD = 'SOCIAL_PROVIDER_DISCORD',
	SOCIAL_PROVIDER_FACEBOOK = 'SOCIAL_PROVIDER_FACEBOOK',
	SOCIAL_PROVIDER_APPLE = 'SOCIAL_PROVIDER_APPLE',
}

export type FeatureFlags = Map<FeatureFlag, boolean>;

export interface FeatureFlagsState {
	features: FeatureFlags;
}

const FEATURE_FLAG_ENV_MAP: PartialRecord<FeatureFlag, keyof ImportMetaEnv> = {
	[FeatureFlag.COUNTRY_CODE_PROFILE]: 'VITE_FEATURE_COUNTRY_CODE_PROFILE',
	[FeatureFlag.COUNTRY_PORTALS]: 'VITE_FEATURE_COUNTRY_PORTALS',
	[FeatureFlag.DRUMS]: 'VITE_FEATURE_DRUMS',
	[FeatureFlag.SOCIAL_LOGIN]: 'VITE_FEATURE_SOCIAL_LOGIN',
	[FeatureFlag.SOCIAL_PROVIDER_GOOGLE]: 'VITE_FEATURE_SOCIAL_PROVIDER_GOOGLE',
	[FeatureFlag.SOCIAL_PROVIDER_DISCORD]: 'VITE_FEATURE_SOCIAL_PROVIDER_DISCORD',
	[FeatureFlag.SOCIAL_PROVIDER_FACEBOOK]: 'VITE_FEATURE_SOCIAL_PROVIDER_FACEBOOK',
	[FeatureFlag.SOCIAL_PROVIDER_APPLE]: 'VITE_FEATURE_SOCIAL_PROVIDER_APPLE',
};

export const composeFeatureFlagURLParam = (featureName: string): string => `feature_${featureName}`;

export const composeFeatureFlagURLParamFromEnum = (featureFlag: FeatureFlag): string => {
	const paramSuffix = String(featureFlag).toLocaleLowerCase();
	return composeFeatureFlagURLParam(paramSuffix);
};

export const isFeatureFlagURLParam = (param: string): boolean => /feature_[\S]+/.test(param);

export const getFeatureStateFromURLParam = (featureFlag: FeatureFlag): boolean | null => {
	const urlParams = new URLSearchParams(window.location.search);
	const featureParameter = urlParams.get(composeFeatureFlagURLParamFromEnum(featureFlag));

	return featureParameter ? featureParameter === 'true' : null;
};

const FEATURE_FLAG_RULES: PartialRecord<FeatureFlag, () => boolean> = {
};

const createFeatureFlagsState = (): FeatureFlagsState => {
	const envVars = Object.keys(import.meta.env) as (keyof ImportMetaEnv)[];

	const features = (Object.keys(FEATURE_FLAG_ENV_MAP) as (keyof typeof FEATURE_FLAG_ENV_MAP)[]).reduce((map, key) => {
		const customRule = FEATURE_FLAG_RULES[key];

		if (customRule) {
			map.set(key, customRule());
			return map;
		}

		const featureFlagURLParamState = getFeatureStateFromURLParam(key);

		if (featureFlagURLParamState !== null) {
			map.set(key, featureFlagURLParamState);
			return map;
		}

		const envVar = FEATURE_FLAG_ENV_MAP[key];

		if (!envVar || !envVars.includes(envVar)) {
			return map;
		}

		map.set(key, !!isTrueValue(import.meta.env[envVar]));

		return map;
	}, new Map() as FeatureFlags);

	logger.info('🐸 Created feature flag state', features.entries());

	return {
		features,
	};
};

export const featureFlagsState: FeatureFlagsState = createFeatureFlagsState();

export const isFeatureFlagEnabled = (featureName: FeatureFlag) => {
	const feature = featureFlagsState.features.get(featureName);

	if (typeof feature !== 'boolean') {
		return false;
	}

	return feature;
};
