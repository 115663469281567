<script setup lang="ts">
import { computed } from 'vue';
import AppModal from '@/components/AppModal.vue';
import { useCustomErrorModal } from '@/store/customErrorModal';
import { useLocalization } from '@/models/Localization';

const store = useCustomErrorModal();

const customErrorModal = computed(() => store.customErrorModal);
const clearCustomErrorModal = () => store.clearCustomErrorModal();
const onButtonClick = async (
	buttonHandler: () => void | Promise<void>,
	closeAction: () => void | Promise<void>,
) => {
	if (typeof buttonHandler === 'function') {
		await buttonHandler();
	}

	closeAction();
};

const { localize } = useLocalization();
</script>

<template>
	<AppModal
		v-if="customErrorModal"
		@after-close="clearCustomErrorModal"
	>
		<template
			v-if="customErrorModal.image"
			#header
		>
			<img
				:src="customErrorModal.image"
				:alt="customErrorModal.image"
			>
		</template>
		<template
			v-if="customErrorModal.title"
			#title
		>
			{{ localize(customErrorModal.title) }}
		</template>
		<template
			v-if="customErrorModal.text"
			#body
		>
			{{ localize(customErrorModal.text) }}
		</template>
		<template
			v-if="customErrorModal.buttons?.length > 0"
			#footer="{ close }"
		>
			<div class="flex flex-col mobile:flex-row whitespace-nowrap items-center gap-4">
				<AppButton
					v-for="(button, $index) in customErrorModal.buttons"
					v-bind="button.props"
					:key="$index"
					@click="onButtonClick(button.handler, close)"
				>
					{{ button.text }}
				</AppButton>
			</div>
		</template>
	</AppModal>
</template>
