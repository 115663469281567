<script lang="ts" setup>
import {
	computed, onMounted, onUnmounted, onUpdated, ref, watch,
} from 'vue';
import TheMobileHeader from '@/components/TheMobileHeader.vue';
import { useRoute, useRouter } from 'vue-router';
import { isEdgeBrowser } from '@/utilities/Helpers';
import { useSubscribeStore } from '@/store/subscribe';

const mainRef = ref<HTMLElement>();
const mainWidth = ref(0);
const cyberweekLink = ref('');
const router = useRouter();
const route = useRoute();

const subscribeStore = useSubscribeStore();
const currentStepIndex = computed(() => subscribeStore.currentStepIndex);

watch(currentStepIndex, () => {
	if (!mainRef.value) return;

	if (isEdgeBrowser()) {
		mainRef.value.scrollTop = 0;
		return;
	}

	mainRef.value.scrollTo({
		top: 0,
	});
});

const setMainWidth = () => {
	if (!mainRef.value) {
		return;
	}

	mainWidth.value = mainRef.value.clientWidth;
};

onMounted(() => {
	const link = router.resolve({ name: 'subscribe', params: { lang: 'de' }, query: { portal: 'cyberweek' } });
	cyberweekLink.value = link.fullPath;
});

const aside = computed(() => route.meta.aside || '');

const footer = computed(() => route.meta.footer || '');

const wrapperClasses = computed(() => ({
	'widescreen:pl-[600px]': aside.value,
	'pb-[78px]': footer.value,
}));

onUpdated(setMainWidth);

onMounted(() => {
	window.addEventListener('resize', setMainWidth);
});

onUnmounted(() => {
	window.removeEventListener('resize', setMainWidth);
});
</script>

<template>
	<div
		class="flex flex-col min-h-screen widescreen:h-screen"
		:class="wrapperClasses"
	>
		<TheMobileHeader />
		<component :is="aside" />
		<div
			id="main"
			ref="mainRef"
			class="main desktop:py-18 py-8 flex-grow desktop:overflow-y-scroll desktop:overflow-x-hidden"
		>
			<transition
				name="fade"
				mode="out-in"
			>
				<slot />
			</transition>
		</div>
		<transition
			name="fade"
			mode="out-in"
			appear
		>
			<div v-if="footer">
				<component :is="footer" />
			</div>
		</transition>
	</div>
</template>

<style scoped>
@media screen and (min-width: 1280px) {
	.main::-webkit-scrollbar {
		-webkit-appearance: none;
	}

	.main::-webkit-scrollbar:vertical {
		width: 11px;
	}

	.main::-webkit-scrollbar:horizontal {
		height: 11px;
	}

	.main::-webkit-scrollbar-thumb {
		border-radius: 8px;
		border: 2px solid white;
		background-color: rgba(0, 0, 0, 0.5);
	}
}
</style>
