<script lang="ts" setup>
import { computed } from 'vue';
import i18n from '@/plugins/i18n';

const props = withDefaults(defineProps<{
	modelValue?: string;
	success?: boolean;
	loading?: boolean;
	buttonLabel?: string;
	inputLabel?: string;
	error?: string;
	icon?: string;
}>(), {
	modelValue: '',
	success: false,
	loading: false,
	buttonLabel: i18n.global.t('button.redeemCouponCode'),
	inputLabel: i18n.global.t('input.yourVoucherCode'),
	error: '',
	icon: 'barcode-alt',
});

const emits = defineEmits<{
	(e: 'update:modelValue', value: string): void;
	(e: 'submit'): void;
}>();

const inputValue = computed({
	get() {
		return props.modelValue;
	},
	set(value: string) {
		emits('update:modelValue', value);
	},
});

const onSubmit = () => {
	inputValue.value = inputValue.value.trim();
	emits('submit');
};
</script>

<template>
	<div class="desktop:flex">
		<div class="flex-grow mr-6">
			<AppInput
				v-model="inputValue"
				:disabled="success"
				:label="inputLabel"
				:error-message="error"
				:icon="icon"
				class="mb-0"
				@keypress.enter="onSubmit"
			/>
		</div>
		<div>
			<AppButton
				:is-loading="loading"
				:is-success="success"
				color="gray"
				data-cy="button_coupon_redeem"
				@click="onSubmit"
			>
				{{ buttonLabel }}
			</AppButton>
		</div>
	</div>
</template>
