import * as Sentry from '@sentry/vue';
import { useToast, type ToastInterface } from '@sunlabde/m2m-library';
import errorMappingData, { ErrorMapping } from '@/data/ErrorMappingData';
import i18n from '@/plugins/i18n';

const { t } = i18n.global;

export class ErrorHandler {
	#errorMapping: ErrorMapping;
	#toast: ToastInterface;

	constructor(errorMapping: ErrorMapping) {
		this.#errorMapping = errorMapping;
		this.#toast = useToast();
	}

	setup(errorMapping: ErrorMapping) {
		if (this.#errorMapping) {
			// eslint-disable-next-line no-console
			console.error('The errorHandler is already set up.');
			return;
		}

		this.#errorMapping = errorMapping;
	}

	report(error: Error) {
		const errorCodes = error.message.split('.');
		const tempErrorCode = [...errorCodes];
		let reportResponse;

		for (let i = 0; i < errorCodes.length; i += 1) {
			if (this.#errorMapping[tempErrorCode.join('.')] !== undefined) {
				reportResponse = this.#errorMapping[tempErrorCode.join('.')];
				break;
			}
			tempErrorCode.pop();
		}

		if (!reportResponse) {
			Sentry.withScope((scope) => {
				scope.setFingerprint(['ErrorHandler', 'Unmapped']);
				Sentry.captureException(error);
			});

			this.#toast.error(t('toast.error.genericTitle'));
			return;
		}

		if (typeof reportResponse === 'function') {
			reportResponse();
			return;
		}

		if (typeof reportResponse === 'string') {
			this.#toast.error(reportResponse);
			return;
		}

		if (typeof reportResponse.content === 'object' && 'localization' in reportResponse.content) {
			this.#toast(reportResponse.content.localization(t), reportResponse.options);
		} else {
			this.#toast(reportResponse.content, reportResponse.options);
		}
	}
}

const errorHandler = new ErrorHandler(errorMappingData);

export default errorHandler;
