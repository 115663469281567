export enum AuthErrorCodes {
	GENERIC = 'auth_error',
	REQUIRED = 'auth_error.required',
	LOGIN_UNAUTHORIZED = 'auth_error.login.unauthorized',
	USER_UNAUTHORIZED = 'auth_error.user.unauthorized',
	TOKEN_UNAUTHORIZED = 'auth_error.token.unauthorized',
	TOKEN_INVALID = 'auth_error.token.invalid',
	COOKIE_INVALID = 'auth_error.cookie.invalid',
	REFRESH_TOKEN_MISSING = 'auth_error.refresh_token.missing',
	REFRESH_TOKEN_INVALID = 'auth_error.refresh_token.invalid',
	ACCESS_TOKEN_MISSING = 'auth_error.authentication.missing',
	ACCESS_TOKEN_INVALID = 'auth_error.auth_token.invalid',
	LOGIN_FAILED = 'auth_error.login.failed',
	LOGOUT_NOT_LOGGED_IN = 'auth_error.logout.notLoggedIn',
	LOGOUT_FAILED = 'auth_error.logout.failed',
	ALREADY_REFRESHING = 'auth_error.alreadyRefreshing',
	USER_RESPONSE_INVALID = 'auth_error.userResponse.invalid',
	MISSING_ACCESS_RIGHTS = 'auth_error.missingAccessRights',
}

export enum APIErrorCodes {
	GENERIC = 'api_error',
	NO_ERROR_CODE = 'api_error.noErrorCode',
}

export enum AppErrorCodes {
	GENERIC = 'app_error',
	PROFILE_COULD_NOT_UPDATE = 'profile.couldNotUpdate',
	CREATE_ORDER_FAILED = 'order.create.failed',
	PAYMENT_ABORTED_BY_USER = 'payment.abortedByUser',
}

export enum CreateSubscriptionErrorCodes {
	NO_NEW_PRIVILEGES = 'create_subscription.no_new_privileges',
}

export enum CouponErrorCodes {
	COUPON_GRANTS_LESS_PRIVILEGES = 'coupon_error.grantsLessPrivileges',
	COUPON_ALREADY_USED = 'coupon_error.alreadyUsed',
	COUPON_NOT_VALID = 'coupon_error.notValid',
}

export enum DiscountErrorCodes {
	DISCOUNT_CODE_NOT_VALID = 'discount_error.codeNotValid',
	BILLWERK_INVALID_COUPON_CODE = 'lookup_discount.billwerk_error.InvalidCouponCode',
}

export enum FormErrorCodes {
	GENERIC = 'form_error',
	INPUTS_MISSING_LOGIN = 'form_error.inputsMissing',
}

export enum CreateUserErrorCodes {
	API_ERROR_NOT_FOUND = 'api_error.not_found',
	API_LOGIN_FAIL = 'api.login.fail',
	API_LOGIN_NOT_APPROVED = 'api.login.not_approved',
	PROFIL_FIRSTNAME = 'create_user.m2m.profil.FirstName',
	PROFIL_LASTNAME = 'create_user.m2m.profil.LastName',
	DUPLICATE_USER_NAME = 'create_user.m2m.DuplicateUserName',
	EMAIL_ALREADY_EXISTS = 'create_user.email_already_exists',
	RESET_PASSWORD_UNKNOWN = 'api.user.reset-password.UnknownError',
}

export enum BillwerkErrorCodes {
	PAYMENT_FAILED = 'billwerk_error.payment_failed',
	NOT_CORRECT_PAYMENT_DATA_PROVIDED = 'billwerk_error.not_correct_payment_data_provided',
}

export const isSupportedAPIError = (errorCode: string): errorCode is APIErrorCodes => Object.values(APIErrorCodes).includes(errorCode as APIErrorCodes);
