import { useI18n } from 'vue-i18n';

export type StaticLinkType = 'custom' | 'signup' | 'marketing';

export type StaticLinkName = 'home' | 'termsOfUse' | 'imprint' | 'dataPrivacy' | 'cancellationPolicy' | 'subscribe' | 'buyGiftCard' | 'redeemCoupon';

export enum StaticLinkLocale {
	DE = 'de',
	EN = 'en',
	ES = 'es',
}

export interface StaticLink {
	type: StaticLinkType;
	link: string | Record<StaticLinkLocale, string>;
}

export const staticLinks: Record<StaticLinkName, StaticLink> = {
	home: {
		type: 'marketing',
		link: '',
	},
	termsOfUse: {
		type: 'marketing',
		link: {
			de: '/de/agb',
			en: '/en/terms-of-use',
			es: '/es/terminos-y-condiciones',
		},
	},
	imprint: {
		type: 'marketing',
		link: {
			de: '/de/impressum',
			en: '/en/imprint',
			es: '/es/declaracion-de-propiedad',
		},
	},
	dataPrivacy: {
		type: 'marketing',
		link: {
			de: '/de/datenschutz',
			en: '/en/data-privacy',
			es: '/es/politica-de-privacidad',
		},
	},
	cancellationPolicy: {
		type: 'marketing',
		link: {
			de: '/de/agb#widerrufsrecht',
			en: '/en/agb#cancellation_policy',
			es: '/es/agb#indicaciones-de-rescisión',
		},
	},
	subscribe: {
		type: 'signup',
		link: '/subscribe',
	},
	buyGiftCard: {
		type: 'marketing',
		link: {
			de: '/de/gutschein-kaufen',
			en: '/en/music2me-gift-card-learn-piano-or-guitar-online',
			es: '/en/music2me-gift-card-learn-piano-or-guitar-online',
		},
	},
	redeemCoupon: {
		type: 'signup',
		link: '/subscribe?type=coupon&step=2',
	},
};

const isSupportedLocale = (locale: string): locale is StaticLinkLocale => Object.values(StaticLinkLocale).includes(locale as StaticLinkLocale);

const getLocalizedLink = (staticLink: StaticLink, locale: StaticLinkLocale): string => (typeof staticLink.link === 'string' ? `/${locale}${staticLink.link}` : staticLink.link[locale]);

export const composeStaticLink = (name: StaticLinkName, locale: string): string => {
	if (!isSupportedLocale(locale)) {
		return '';
	}

	const staticLink = staticLinks[name];

	if (staticLink.type === 'marketing') {
		const prefix = import.meta.env.VITE_MARKETING_URL;
		const localeLink = getLocalizedLink(staticLink, locale);

		return `${prefix}${localeLink}`;
	}

	if (staticLink.type === 'signup') {
		const prefix = import.meta.env.VITE_SIGNUP_URL;
		const localeLink = getLocalizedLink(staticLink, locale);

		return `${prefix}${localeLink}`;
	}

	return typeof staticLink.link === 'string' ? staticLink.link : staticLink.link[locale];
};

export const useStaticLinkComposer = () => {
	const i18n = useI18n();

	const compose = (name: StaticLinkName) => composeStaticLink(name, i18n.locale.value);

	return {
		compose,
	};
};
