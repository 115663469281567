import { LocationQueryValue } from 'vue-router';
import { PlanCategory } from './PlanCategory';
import { SignupType } from './SignupType';

export interface SignupPortalOptions {
	logo?: string;
	trial?: boolean;
	hasPlans?: boolean;
	preselectedCategoryId?: PlanCategory;
	discountCode?: string;
	defaultSignupType?: SignupType;
}

export interface SignupPortal {
	name: string;
	options: SignupPortalOptions;
	allowsTrialOrder: Readonly<boolean>;
}

export const SIGNUP_PORTALS: SignupPortal[] = [
	{
		name: 'default',
		allowsTrialOrder: true,
		options: {
			hasPlans: true,
			defaultSignupType: 'default',
		},
	},
];

export const createSignupPortal = (name: string, options: SignupPortalOptions): SignupPortal => {
	const allowsTrialOrder = () => {
		if (options.trial !== undefined) {
			return options.trial;
		}

		return true;
	};

	return {
		name,
		options,
		allowsTrialOrder: allowsTrialOrder(),
	};
};

export const addSignupPortal = (portal: SignupPortal | SignupPortal[]): SignupPortal[] => {
	if (Array.isArray(portal)) {
		portal.forEach((p) => SIGNUP_PORTALS.push(p));
	} else {
		SIGNUP_PORTALS.push(portal);
	}

	return SIGNUP_PORTALS;
};

export const isValidPortal = (name: string | LocationQueryValue[]) => SIGNUP_PORTALS.some((portal) => portal.name === name);

export const getPortalByName = (name: string | LocationQueryValue[]) => SIGNUP_PORTALS.find((portal) => portal.name === name);

export const portalAllowsTrialOrder = (name: string) => {
	const portal = getPortalByName(name);

	if (!portal) {
		return true;
	}

	return portal.allowsTrialOrder;
};

export const portalHasCustomPlans = (inputPortal: string | SignupPortal) => {
	const portal = typeof inputPortal === 'string' ? getPortalByName(inputPortal) : inputPortal;

	if (!portal) {
		return false;
	}

	if (portal && Object.keys(portal.options).includes('hasPlans')) {
		return portal.options.hasPlans;
	}

	return false;
};

export const getPortalLogoByName = (name: string) => {
	const portal = getPortalByName(name);

	if (portal && portal.options.logo !== undefined) {
		return portal.options.logo;
	}

	return null;
};
